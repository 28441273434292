/* Plugins */
import React from 'react';
import Markdown from 'react-markdown';
import rehypeRaw from 'rehype-raw';

/* Messages */
import {
  SEO, Container, Section, Article, Heading,
} from '../components/components';

/* Messages */
import * as messages from '../messages/polityka-prywatnosci.messages';

const PolitykaPrywatnosci = () => (
  <>
    <SEO title={messages.mainHeader} />
    <Container subPage>
      <Heading headingLevel="h1" title={messages.mainHeader} centered />
      <Section>
        <Article>
          {messages.content.map(({ paragraph }) => (
            <Markdown
              key={paragraph}
              rehypePlugins={[rehypeRaw]}
              children={paragraph}
            />
          ))}
        </Article>
      </Section>
    </Container>
  </>
);

export default PolitykaPrywatnosci;
